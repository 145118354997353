import { render, staticRenderFns } from "./Lgx296721465602998272.vue?vue&type=template&id=3d877c65&scoped=true&"
import script from "./Lgx296721465602998272.vue?vue&type=script&lang=js&"
export * from "./Lgx296721465602998272.vue?vue&type=script&lang=js&"
import style0 from "./Lgx296721465602998272.vue?vue&type=style&index=0&id=3d877c65&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d877c65",
  null
  
)

export default component.exports