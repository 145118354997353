/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/4">
        <vx-card class="p-4">
          <h1 class="font-bold mb-2">
            Authentication should be enforced in the settings of the mobile device using the following parameters
          </h1>
          <h2>
            • Username: tech@techies.com<br />
            • Password: P@$$w0rd<br />
            • Outgoing server: mail.techies.com<br />
            • Incoming server: pop3.techies.com<br />
            Company SSID: Techies (Already Connected)<br />
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-3/4">
        <vx-card>
          <div v-for="section in configureWiFi" :key="section.id" class="p-5 border-solid mb-3 rounded">
            <h3 class="font-bold break-all">{{ section.name }}</h3>
            <vs-divider />

            <div class="grid grid-cols-2 gap-4">
              <div v-for="(field, field_i) in section.fields" :key="field_i" class="mt-4">
                <div class="" v-if="field.type === 'html'" v-html="field.html"></div>
                <div class="vx-row" v-if="field.type !== 'html'">
                  <div class="vx-col flex w-1/3">
                    <h3 class="break-all self-center">{{ field.name }}</h3>
                  </div>
                  <div class="vx-col w-2/3">
                    <v-select
                      v-if="field.type === 'dropdown'"
                      :options="field.options"
                      v-model="field.value"
                      :disabled="field.disabled"
                      class="w-full self-center"
                      placeholder="Select Option From Dropdown..."
                      size="large"
                    />
                    <vs-input
                      v-if="field.type === 'text'"
                      v-model="field.value"
                      :readonly="field.disabled"
                      class="inputx w-full self-center"
                    />
                    <div class="flex items-center" v-if="field.type === 'switch'">
                      <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                      <h4 class="ml-2">{{ field.desc }}</h4>
                    </div>
                    <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                    <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      configureWiFi: [
        {
          id: 0,
          name: 'Email Settings',
          fields: [
            {
              name: 'Username/Email',
              type: 'text',
              value: '',
              answer: ['tech@techies.com'],
              disabled: false,
              score: null,
            },
            {
              name: 'Password',
              type: 'text',
              value: '',
              answer: ['P@$$w0rd'],
              disabled: false,
              score: null,
            },
          ],
        },
        {
          id: 0,
          name: 'Incoming Server',
          fields: [
            {
              name: 'Protocol',
              type: 'dropdown',
              value: '',
              answer: ['POP3'],
              disabled: false,
              options: ['IMAP3', 'POP3', 'SMTP', 'NONE'],
              score: null,
            },
            {
              name: 'Encryption',
              type: 'dropdown',
              value: '',
              answer: ['TLS'],
              disabled: false,
              options: ['SSL', 'TLS', 'NONE'],
              score: null,
            },
            {
              name: 'Server Address',
              type: 'text',
              value: '',
              answer: ['pop3.techies.com'],
              disabled: false,
              score: null,
            },
            {
              name: 'Port',
              type: 'text',
              value: '',
              answer: ['995'],
              disabled: false,
              score: null,
            },
          ],
        },
        {
          id: 0,
          name: 'Incoming Server',
          fields: [
            {
              name: 'Protocol',
              type: 'dropdown',
              value: '',
              answer: ['SMTP'],
              disabled: false,
              options: ['IMAP3', 'POP3', 'SMTP', 'NONE'],
              score: null,
            },
            {
              name: 'Encryption',
              type: 'dropdown',
              value: '',
              answer: ['TLS'],
              disabled: false,
              options: ['SSL', 'TLS', 'NONE'],
              score: null,
            },
            {
              name: 'Server Address',
              type: 'text',
              value: '',
              answer: ['mail.techies.com'],
              disabled: false,
              score: null,
            },
            {
              name: 'Port',
              type: 'text',
              value: '',
              answer: ['587'],
              disabled: false,
              score: null,
            },
            {
              name: 'Username/Email',
              type: 'text',
              value: '',
              answer: [''],
              disabled: true,
              score: null,
            },
            {
              name: 'Password',
              type: 'text',
              value: '',
              answer: [''],
              disabled: true,
              score: null,
            },
            {
              html: 'Outgoing Server does not require authentication',
              type: 'html',
            },
          ],
        },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.configureWiFi.forEach((category, category_i) => {
        if (category.fields) {
          category.fields.forEach((field, field_i) => {
            if (field.type !== 'html') {
              if (field.answer.includes(field.value)) {
                this.configureWiFi[category_i].fields[field_i].score = true;
                totalScore++;
              } else {
                this.configureWiFi[category_i].fields[field_i].score = false;
              }
            }
          });
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx201394311844069376.scss';

button.vs-tabs--btn {
  color: #ffffff;
}
</style>
